import { useRouter } from 'next/router';
import Link from "next/link";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight, faHome } from '@fortawesome/free-solid-svg-icons';

const Breadcrumb = ({ customClass = null }) => {
    const router = useRouter();
    const pathArray = router.asPath.split('/').filter(Boolean);

    const renderBreadcrumbItem = (path, index) => {
        const isLast = index === pathArray.length - 1;
        let displayText = path.replace(/-/g, ' ');
        const questionMarkIndex = path.indexOf('?');
        if (questionMarkIndex !== -1) {
            displayText = path.substring(0, questionMarkIndex).replace(/-/g, ' ');
        }

        if (isLast) {
            return (
                <li className="text-xs capitalize truncate whitespace-nowrap" key={index}>
                    {displayText}
                </li>
            );
        }

        return (
            <li className="flex items-center text-xs whitespace-nowrap" key={index}>
                {
                    path === 'sample-request' ? <>Request Sample Page <FontAwesomeIcon className='px-2 text-sm text-gray-400' size="sm" icon={faChevronRight} /></> : <Link href={`/${path === 'report-store' ? 'report-store.php' : path}`} className="text-decoration-none text-[#1b5df3]">
                        {<span className='capitalize'>{path?.replace(/-/g, ' ')}</span>}
                        <FontAwesomeIcon className='px-2 text-sm text-gray-400' size="sm" icon={faChevronRight} />
                    </Link>
                }

            </li>
        );
    };

    return (
        <>
            <div className={`w-11/12 xl:max-w-screen max-w-screen-2xl flex flex-col justify-center mx-auto border-b ${customClass && customClass}`}>
                <div className='grid grid-cols-12'>
                    <div className='col-span-12'>
                        <div className="py-3">
                            <ul className="flex items-center p-0">
                                <li className="flex items-center text-xs whitespace-nowrap">
                                    <Link href="/" className="text-[#1b5df3]  ">
                                        <FontAwesomeIcon className='pr-4' icon={faHome} size="sm" />
                                        Home <FontAwesomeIcon className='px-2 text-gray-400' size="sm" icon={faChevronRight} />
                                    </Link>
                                </li>
                                {pathArray.map((path, index) => renderBreadcrumbItem(path, index))}
                            </ul>
                        </div>

                    </div>
                </div>
            </div>
        </>
    );
};

export default Breadcrumb;
